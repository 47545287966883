import React from 'react'
import './home.css'
import { Box, Typography, Button } from '@mui/material'
import Container from '@mui/material/Container'

const Home = () => {
    return (
        <Container sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
        }}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
            }}>
                <Box sx={{ marginTop: 6, display: 'flex', flexDirection: 'column', }}>
                    <Typography
                        variant="h4"
                        component="h11">
                        Welcome to the Joey's world!
                    </Typography>
                    <Box sx={{'& button': {
                            marginTop: 2,
                            paddingLeft: 3,
                            paddingRight: 3,
                            paddingBottom: 1,
                            paddingTop: 1,
                            backgroundColor: '#004b44',
                            color: '#fff',
                            borderRadius: 0,
                            boxShadow: 0,
                            fontSize: 10,
                        }
                    }}>
                        <Button variant="contained" onClick={() => {
                            alert('Coming soon...');
                        }}>Shop Now</Button>
                    </Box>
                </Box>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-end',
                }}>
                    <Box sx={{
                        width: 160,
                        height: 200,
                        marginRight: 2,
                        backgroundColor: '#bdd3b3',
                    }} />
                    <Box sx={{
                        width: 300,
                        height: 400,
                        backgroundColor: '#9acbca',
                    }} />
                </Box>
            </Box>
        </Container>
    )
}

export default Home