import React, { useState } from 'react'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Logo from '../logo/Logo'
import IconButton from '@mui/material/IconButton'
import Button from '@mui/material/Button'
import { Menu, Close } from '@mui/icons-material'
import Navigation from '../navbar/Navbar'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { yellow } from '@mui/material/colors'

const Header = () => {
  return (
    <Container maxWidth="m">
      <Box>
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-evenly',
        }}>
          <Logo />
          <Navigation />
          <Box sx={{
            alignSelf: 'center', '& button': {
              m: 1,
              paddingLeft: 4,
              paddingRight: 4,
              paddingBottom: 1,
              paddingTop: 1,
              backgroundColor: '#fad882',
              color: '#111111',
              borderRadius: 0,
              boxShadow: 0,
              fontSize: 10,
            }
          }}>
            <Button variant="contained" size="small" onClick={() => {
              alert('Coming soon...');
            }}>
              Contact us
            </Button>
          </Box>
        </Box>
      </Box>
    </Container>
  )
}

export default Header