import React from 'react'
import { Box, Container, Typography } from '@mui/material'
import './footer.css'

const Footer = () => {
  return (
    <Box sx={{
      p: 2,
      backgroundColor: 'background.paper',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }}>
      <Typography sx={{ color: '#11111', fontWeight: '700', fontSize: 12 }}>
        All right reserved © JoeyThePawsome 2023
      </Typography>
    </Box>
  )
}

export default Footer