import React from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import './feature.css'

const Feature = () => {
  return (
    <Box sx={{
    }}>
      <Box id="feature" sx={{py: { xs: 10, md: 14, height: 50, }, backgroundColor: '#eff7f9'}}>
        <Container>
        </Container>
      </Box>
    </Box>
  )
}

export default Feature