import type Navigation  from '../interfaces/navigation'

export const navigations: Navigation[] = [
  {
    label: 'Home',
    path: '#', // '/',
  },
  {
    label: 'Feature',
    path: 'feature', // '/feature',
  },
  {
    label: 'Brands',
    path: 'brands', // '/brands we worked with',
  },
  {
    label: 'About us',
    path: 'about-us', // '/about us',
  },
]