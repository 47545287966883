import React from 'react';
import { Box } from '@mui/material'

import { Header, Brands, Footer, Feature, Home } from './components';

import './App.css';

const App = () => (
  <Box component="main" className="App">
    <Box sx={{
      backgroundColor: '#fcf3eb'
    }}>
      <Header />
      <Home />
    </Box>
    <Feature />
    <Footer />
  </Box>
);

export default App;