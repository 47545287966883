import { Box, Typography } from '@mui/material'
import '@fontsource/caveat-brush';
import { BorderColor } from '@mui/icons-material';

const Logo = ({ onClick, variant }) => {
  return (
    <Box onClick={onClick}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
      <Box>
        <img src="images/logo.png" width={70} height={70} quality={100} alt="Logo img" />
      </Box>
      <Box sx={{
        margin: 2,
      }}>
        <Typography
          sx={{
            fontSize: 40,
            color: '#008ba2',
            fontFamily: 'Caveat Brush',
          }}>
          Joey<span>ThePawsome</span>
        </Typography>
      </Box>
    </Box>
  )
}

export default Logo